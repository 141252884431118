@import '~static/styles/mixins';
@import '~static/styles/variables';

.SpeedBoxStatsFilterModal {
  max-width: 300px;
  width: 100%;

  .title {
    margin: 0 0 16px;
  }

  .clonesSwitcher {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    margin: 0 0 16px;

    .option {
      padding: 8px;

      color: $dark-gray;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 0;
      border-bottom: 1px solid $dark-gray;

      &.selected {
        color: $green;
        border-color: $green;
      }
    }
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin: 0 0 40px;

    .filter {
      display: flex;
      flex-direction: column;

      .label {
        margin: 0 0 8px;
      }

      .options {
        display: grid;
        width: 100%;
        gap: 8px;

        &.line {
          grid-template-columns: repeat(5, 1fr);
        }

        &.places {
          grid-template-columns: repeat(3, 1fr);

          .filterOption {
            border-radius: 9999px;
          }
        }
      }

      .filterOption {
        width: 100%;
        height: 38px;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #b4b4b4;

        color: $night-green;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;

        &.active {
          color: $white;
          background: $dark-green;
          box-shadow: inset 0 0 0 1px $light-green;
        }
      }

      .deskGroup {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          margin: 0 0 8px;
        }

        .label {
          color: $black;
          font-weight: 700;
          display: flex;
          align-items: center;

          .crown {
            width: 25px;
            height: auto;
          }
        }

        .options {
          grid-template-columns: repeat(3, 1fr);
          width: 100%;
        }

        .filterOption.active {
          box-shadow: none;
        }

        &.s1 {
          .filterOption.active {
            background: #5363b7;
            box-shadow: 2px 2px 10.3px 0px rgba(#404b97, 0.6);
          }
        }

        &.s2 {
          .filterOption.active {
            background: rgba(#5901fe, 0.7);
            box-shadow: 2px 2px 10.3px 0px rgba(#874afd, 0.42);
          }
        }

        &.s3 {
          .filterOption.active {
            background: rgba(#d1135f, 0.7);
            box-shadow: 2px 2px 10.3px 0px rgba(#db578e, 0.6);
          }
        }

        &.s4 {
          .filterOption.active {
            background: rgba(#c100c9, 0.7);
            box-shadow: 2px 2px 10.3px 0px rgba(#d04ad8, 0.6);
          }
        }

        &.s5 {
          .filterOption.active {
            background: rgba(#e80b16, 0.7);
            box-shadow: 2px 2px 10.3px 0px rgba(#ec515b, 0.6);
          }
        }
      }
    }
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
